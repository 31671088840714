import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Animer ses SVG avec Snap.svg",
  "description": "Cet article montre les possibilités offertes par Snap.svg pour animer sans prise de tête vos SVG de manière convaincante (avec des exemples à l’appui).",
  "date": "2014-07-21T00:00:00.000Z",
  "path": "/articles/animer-ses-svg-avec-snap-svg/",
  "archive": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Ces derniers temps, j’ai un peu expérimenté l’animation de SVG. Mon premier essai a été cet `}<a parentName="p" {...{
        "href": "http://codepen.io/eskiiss/full/kFphn"
      }}>{`oiseau`}</a>{`, dont les différents éléments sont animés avec des keyframes. Mais ça reste limité en termes de possibilités : si je veux pouvoir modifier le tracé même de mon SVG de manière fluide, la meilleure solution que j’ai trouvée est `}<a parentName="p" {...{
        "href": "http://snapsvg.io/"
      }}>{`Snap.svg`}</a>{`. Je vais vous expliquer le processus en détails.`}</p>
    <p className="info">
  Attention, pour bien saisir ce tutoriel il est préférable d’être à l’aise sur
  le mode de fonctionnement des SVG.
    </p>
    <h2>{`L’idée de départ`}</h2>
    <p>{`Je suis parti sur une idée relativement simple : je crée des smileys sous Sketch, chacun avec deux états différents, et je fais en sorte de les faire changer d’état au clic.`}</p>
    <p><a parentName="p" {...{
        "href": "http://codepen.io/eskiiss/full/gxqsr"
      }}>{`Voir la démo`}</a></p>
    <p>{`Pour se faciliter la vie, l’idée est de pouvoir animer chacune des parties du smiley indépendamment. La façon de faire est assez simple; on applique une ID à la section de notre SVG en question, et on la lie à Snap.Svg. Par exemple :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "js"
      }}><code parentName="pre" {...{
          "className": "js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` smiley `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Snap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`select`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'#smiley'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  smileyBody `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Snap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`select`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'#smiley__body'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  smileyMouth `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Snap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`select`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'#smiley__mouth'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Libre à nous de les animer ensuite comme l’on souhaite. Pour toggle les changements d’état, on applique un callback au `}<code parentName="p" {...{
        "className": "text"
      }}>{`smiley.click`}</code>{` pour utiliser la fonction souhaitée (ici, `}<code parentName="p" {...{
        "className": "text"
      }}>{`smileyDef()`}</code>{` et `}<code parentName="p" {...{
        "className": "text"
      }}>{`smileyToggle`}</code>{`).`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "js"
      }}><code parentName="pre" {...{
          "className": "js"
        }}>{`toggle1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
smiley`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`click`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`toggle1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`smileyDef`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    toggle1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`smileyToggle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    toggle1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2>{`Animation du corps`}</h2>
    <p>{`Les modifications apportées au corps sont assez simples : on change uniquement la couleur du fond et de la bordure. Cela se fait de la manière suivante :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "js"
      }}><code parentName="pre" {...{
          "className": "js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`smileyToggle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  smileyBody`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`animate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`fill`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'#A4DA69'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`stroke`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'#5B9020'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`150`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` mina`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ease`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><code parentName="p" {...{
        "className": "text"
      }}>{`150`}</code>{` représente la durée de l’animation et `}<code parentName="p" {...{
        "className": "text"
      }}>{`mina.ease`}</code>{` le type d’easing souhaité.`}</p>
    <h2>{`Animation de la bouche`}</h2>
    <p>{`Il va s’agir de modifier le `}<code parentName="p" {...{
        "className": "text"
      }}>{`path`}</code>{` de la bouche pour faire arborer à notre smiley un gros sourire. Cela se fait d’une manière identique à l’animation des couleurs du body :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "js"
      }}><code parentName="pre" {...{
          "className": "js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`smileyToggle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  smileyMouth`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`animate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`d`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'M90.7207031,217.580078 C129.926268,287.516842 285.592097,293.239314 300.232422,205.320312'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`stroke`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'#5B9020'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token number"
          }}>{`150`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    mina`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ease
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2>{`Animation des sourcils`}</h2>
    <p>{`Sketch a un peu compliqué les choses sur cette partie, puisque pour certains de mes soucils il a utilisé des `}<code parentName="p" {...{
        "className": "text"
      }}>{`path`}</code>{` et pour d’autres des `}<code parentName="p" {...{
        "className": "text"
      }}>{`polygon`}</code>{`. J’ai décidé de garder cette structure afin de me familiariser un peu plus avec les fonctionnalités de base de Snap.svg.`}</p>
    <p>{`Pour les `}<code parentName="p" {...{
        "className": "text"
      }}>{`path`}</code>{`, j’ai comme pour ma bouche simplement modifié mon attribut `}<code parentName="p" {...{
        "className": "text"
      }}>{`d`}</code>{`. Pour les `}<code parentName="p" {...{
        "className": "text"
      }}>{`polygon`}</code>{`, j’ai dû appliquer des transformations pour que le sourcil en question se déplace à l’endroit voulu.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "js"
      }}><code parentName="pre" {...{
          "className": "js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`smileyToggle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  smileyLeftEyebrow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`animate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'t0,-30'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`fill`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'#5B9020'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token number"
          }}>{`150`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    mina`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ease
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Les transformations sur Snap.svg ont une syntaxe assez spéciale. Ainsi, `}<code parentName="p" {...{
        "className": "text"
      }}>{`t0,-30`}</code>{` désigne une translation des valeurs x et y à 0 et -30 dans l’espace. Pour plus d’infos sur le sujet, je vous conseille de lire cet `}<a parentName="p" {...{
        "href": "http://stackoverflow.com/questions/20302801/how-do-i-understand-transform-properties-in-snap-svg"
      }}>{`article`}</a>{` sur Stackoverflow.`}</p>
    <h2>{`Quelques précisions pour finir`}</h2>
    <p>{`Puisque mes animations sont basée sur un retour à l’état initial, il est nécessaire de prévoir une fonction qui réinitialisera les valeurs par défaut du SVG. (D’où la fonction `}<code parentName="p" {...{
        "className": "text"
      }}>{`smileyDef()`}</code>{`).`}</p>
    <p>{`Le détail de mon code est disponible ci-dessous, mais gardez en tête que ça n’est qu’une démo et que je n’ai pas vraiment cherché à l’optimiser. J’imagine qu’il sera plus facile à lire ainsi.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      